import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { TemplateRef } from '@angular/core';

// Components
import { FactoryDialogComponent } from './factory-dialog.component';

// Models
export interface DialogData<T = undefined> {
  template: TemplateRef<NgTemplateOutlet>;
  context?: T;
}

export interface DialogOptions {
  width?: number | string;
  disableClose?: boolean;
  panelClass?: string;
  exitAnimationDuration?: string;
  enterAnimationDuration?: string;
}

// Services
import { DialogService } from './dialog.service';
import { NgTemplateOutlet } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DialogFactoryService<T = undefined> {
  constructor(private dialog: MatDialog) {}

  open(
    dialogData: DialogData<T>,
    options: DialogOptions = {
      width: 'auto',
      disableClose: false,
      panelClass: 'custom-dialog',
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
    }
  ): DialogService<T> {
    const dialogRef = this.dialog.open<FactoryDialogComponent<T>, DialogData<T>>(FactoryDialogComponent, {
      ...this.fetchOptions(options),
      data: dialogData,
    });

    dialogRef.afterClosed().pipe(first());

    return new DialogService(dialogRef);
  }

  close(): void {
    this.dialog.closeAll();
  }

  private fetchOptions({
    width,
    disableClose,
    panelClass,
  }: DialogOptions): Pick<
    MatDialogConfig<DialogData<T>>,
    'width' | 'disableClose' | 'panelClass' | 'exitAnimationDuration' | 'enterAnimationDuration'
  > {
    return {
      width: `${width}`,
      disableClose,
      panelClass,
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
    };
  }
}
