import { CommonModule } from '@angular/common';
import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-factory-dialog',
  template: `<div class="closed-dialog">
      <span class="material-icons md-36 cursor-pointer" mat-dialog-close>close</span>
    </div>
    <mat-dialog-content>
      <ng-container [ngTemplateOutlet]="data.template" [ngTemplateOutletContext]="data.context"></ng-container>
    </mat-dialog-content>`,
  styleUrls: ['./factory-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule],
})
export class FactoryDialogComponent<T> {
  constructor(
    public dialogRef: MatDialogRef<FactoryDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      template: TemplateRef<any>;
      context: T;
    }
  ) {}
}
